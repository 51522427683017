.one-on-one-prep-container {
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  border-radius: 28px;
  border: 1px solid #e6e6e7;
  background: #fff;
  margin-top: 12px;
  .header-container {
    padding: 24px;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    border-bottom: 1px solid #e6e6e7;
    background: #fafafa;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right-header-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .subtext {
        color: #c2c3c5;
        font-family: "Helvetica Now Display", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 100% */
        // letter-spacing: -0.16px;
      }
    }
  }

  .container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    .question-container {
      padding: 16px;
      border-radius: 12px;
      background: #fff;
      border: 1px solid #e0e0e0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
      display: flex;
      gap: 10px;
      .number-index {
        color: #dbdbdc;
        font-family: "Helvetica Now Display", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 100% */
      }
      .question {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 8px;
        line-height: 20px;
      }

      .reviewer-answer {
        font-size: 14px;
        color: #595959;
        line-height: 18px;
        display: flex;
        gap: 4px;
        margin-top: 12px;
        .reviewer {
          font-size: 14px;
          font-weight: 500;
          color: #0a0d16;
          min-width: 60px;
          max-width: 70px;
      
          word-wrap: break-word; 
          overflow-wrap: break-word;
          white-space: normal; 
        }
        .answer-text{
        white-space: pre-line;
        }
      }
    }
  }
}
