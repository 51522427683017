@font-face {
  font-family: circular std;
  font-style: normal;
  font-weight: 450;
  src: local("Circular Std"),
    url(./../../assets/fonts/CircularStd-Book.woff) format("woff");
}

@font-face {
  font-family: circular std;
  font-style: italic;
  font-weight: 450;
  src: local("Circular Std"),
    url(./../../assets/fonts/CircularStd-BookItalic.woff) format("woff");
}

@font-face {
  font-family: circular std;
  font-style: normal;
  font-weight: 500;
  src: local("Circular Std"),
    url(./../../assets/fonts/CircularStd-Medium.woff) format("woff");
}

@font-face {
  font-family: circular std;
  font-style: italic;
  font-weight: 500;
  src: local("Circular Std"),
    url(./../../assets/fonts/CircularStd-MediumItalic.woff) format("woff");
}

@font-face {
  font-family: circular std;
  font-style: normal;
  font-weight: 700;
  src: local("Circular Std"),
    url(./../../assets/fonts/CircularStd-Bold.woff) format("woff");
}

@font-face {
  font-family: circular std;
  font-style: italic;
  font-weight: 700;
  src: local("Circular Std"),
    url(./../../assets/fonts/CircularStd-BoldItalic.woff) format("woff");
}

@font-face {
  font-family: circular std;
  font-style: normal;
  font-weight: 900;
  src: local("Circular Std"),
    url(./../../assets/fonts/CircularStd-Black.woff) format("woff");
}

@font-face {
  font-family: circular std;
  font-style: italic;
  font-weight: 900;
  src: local("Circular Std"),
    url(./../../assets/fonts/CircularStd-BlackItalic.woff) format("woff");
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-Black.ttf")
    format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-BlackIta.ttf")
    format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-BoldIta.ttf")
    format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-ExtBdIta.ttf")
    format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-ExtBlk.ttf")
    format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-ExtBlkIta.ttf")
    format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-ExtLt.ttf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-ExtLtIta.ttf")
    format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-ExtraBold.ttf")
    format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-Hairline.ttf")
    format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-HairlineI.ttf")
    format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-Light.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-LightIta.ttf")
    format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-MedIta.ttf")
    format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-RegIta.ttf")
    format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-Thin.ttf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./../../assets/fonts/HelveticaNowDisplay-ThinIta.ttf")
    format("truetype");
  font-weight: 200;
  font-style: italic;
}
