.AiFocus {
  padding: 16px;
  display: flex;
  flex-direction: column;
  .label-field {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 32px;
    padding-top: 16px;
    border-top: 1px solid #e6e6e7;
    .subtext {
      color: #c2c3c5;
      font-family: "Helvetica Now Display", sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px; /* 100% */
      letter-spacing: -0.075px;
    }
  }
  .btn-container {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
  }
}
