.EditFormModalPrepSurvey {
  max-height: 100%;
  .form-section {
    overflow-y: auto;
    height: 100%;
  }
  .submit-section {
    padding: 24px 12px;
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 14px;
    border-top: 1px solid #e6e6e7;
  }
}

.edit-survey-loader {
  height: 300px;
  width: 100%;
}
