.TabButton {
    display: flex;
    padding: 12px 4px;
    justify-content: center;
    align-items: center;
    color: #c2c3c5;
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.16px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-transform: capitalize;
}
