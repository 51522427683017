.EngagementStatusView {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    .topic {
        color: #c2c3c5;
        font-family: 'Helvetica Now Display', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.07px;
    }
    .value {
        color: '#0a0d16';
        font-family: 'Helvetica Now Display', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.07px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        height: fit-content;
        padding: 2px 0;
    }
}
