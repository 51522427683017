.ContextSelectionModal {
  width: 426px;
  padding: 16px;
  border-radius: 28px;
  border-top: 1px solid #e6e6e7;
  background: #fafafa;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08),
    0px 4px 32px 0px rgba(0, 0, 0, 0.08);
  .text-area-container {
    width: 100%;
    height: 160px;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #e6e6e7;
    background: #fff;
    display: flex;
    flex-direction: column;
    .input-container {
      flex: 1;
    }
    .button-container {
      display: flex;
      height: 34px;
      gap: 4px;
    }
  }
  .submit-container {
    margin-top: 16px;
    height: 36px;
    display: flex;
    justify-content: flex-end;
    gap: 4px;
  }
}
