.OneOnOneNotes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 24px;
  border-radius: 28px;
  border: 1px solid #e6e6e7;
  background: #fff;
  margin-top: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  .header-container {
    width: 100%;
    padding: 24px;
    background: #fafafa;
    border-bottom: 1px solid #e6e6e7;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .subtext {
      color: #c2c3c5;
      font-family: "Helvetica Now Display", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 100% */
      letter-spacing: -0.16px;
    }
  }

  .container {
    padding: 24px;
    display: flex;
    flex-direction: column;

    .no-notes {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Helvetica Now Display", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 100% */
      letter-spacing: -0.16px;
    }
    .notes-form {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .submit-note {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
      }
      .note {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .label {
          font-family: "Helvetica Now Display", sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 100% */
          letter-spacing: -0.16px;
        }
        .notes-area {
          width: 100%;
          height: 130px;
          resize: none;
          border: 1px solid #e6e6e7;
          border-radius: 28px;
          padding: 20px;
          outline: none;
        }
      }
    }

    .render-notes {
      border-radius: 28px;
      padding: 12px 16px;
      margin-top: 12px;
      border: 1px solid #e6e6e7;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
      .header {
        font-size: 18px;
        font-weight: 600;
        color: #333;
        margin-bottom: 8px;
        font-family: "Helvetica Now Display", sans-serif;
      }

      .sub-header {
        font-size: 14px;
        font-weight: 500;
        color: #666;
        margin-bottom: 4px;
        font-family: "Helvetica Now Display", sans-serif;
      }

      ul {
        padding-left: 16px;
        margin: 0;
        list-style-type: disc;
        font-family: "Helvetica Now Display", sans-serif;

        li {
          font-size: 16px;
          color: #333;
          margin-bottom: 4px;

          &:hover {
            color: #555;
            transition: color 0.3s ease;
          }
        }
      }
    }
  }
}
