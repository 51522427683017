.AiQuestionCard {
  border-top: 1px solid #e6e6e7;
  margin-top: 32px;
  padding-top: 12px;
  display: flex;
  gap: 12px;
  width: 100%;
  height: 240px;
  .AiCardInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .label-flex {
      display: flex;
      flex-direction: column;
      gap: 6px;
      .question {
        color: #c2c3c5;
        font-family: "Helvetica Now Display", sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px; /* 100% */
        letter-spacing: -0.075px;
      }
    }
    .options {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }
  }
  .text-container {
    flex: 1;
    min-width: 200px;
    padding: 16px;
    height: 100%;
    border-radius: 16px;
    border: 1px solid #e6e6e7;
  }
}
