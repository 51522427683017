.AiToolsDescriptionBadge {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 36px;
  .labels {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    .label {
      color: #dbdbdc;
      font-family: "Helvetica Now Display", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      white-space: nowrap;
    }
    .value {
      color: #0a0d16;
      font-family: "Helvetica Now Display", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }
  .desc {
    color: #97989b;
    font-family: "Helvetica Now Display", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.07px;
    flex: 1;
    min-width: 200px;
  }
}
