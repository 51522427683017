.HeaderText {
  color: #0a0d16;
  font-family: "Helvetica Now Display", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
