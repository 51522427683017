.TertiaryButton {
  all: unset;
  box-sizing: border-box;
  height: 36px;
  display: flex;
  padding: 11px 12px;
  align-items: center;
  gap: 6px;
  justify-content: center;
  border-radius: 18px;
  border: 1px solid #dbdbdc;
  background: #fff;
  transition: all 0.15s ease-in-out;
  color: #3d3f44;
  font-family: "Helvetica Now Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  letter-spacing: -0.07px;
  cursor: pointer;
}

.TertiaryButton:hover {
  border: 1px solid #dbdbdc;
  background: #fafafa;
}
