.AttributesBadgesCard {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  margin-top: 32px;
  border-top: 1px solid #e6e6e7;
  min-width: 40px;
  gap: 16px;
  .badge-container {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    .plusIcon {
      display: flex;
      width: 34px;
      height: 34px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 18px;
      background: #784dff;
      color: white;
      cursor: pointer;
      position: relative;
      .options-card {
        position: absolute;
        display: flex;
        width: 426px;
        flex-direction: column;
        align-items: flex-start;
        height: 202px;
        background-color: white;
        bottom: 50px;
        right: 0;
        border-radius: 28px;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08),
          0px 4px 32px 0px rgba(0, 0, 0, 0.08);
        .header {
          display: flex;
          padding: 12px 12px 12px 24px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          background: #fafafa;
        }
        .body-container {
          width: 100%;
          height: calc(100% - 60px);
          display: flex;
          gap: 4px;
          row-gap: 2px;
          flex-wrap: wrap;
          border-top: 1px solid #e6e6e7;
          background: white;
          border-bottom-left-radius: 28px;
          border-bottom-right-radius: 28px;
          overflow-y: auto;
          padding: 16px;
          scrollbar-width: none;
          -ms-overflow-style: none;
          .btn-wrapper {
            height: 37px;
            width: fit-content;
          }
        }
        .body-container::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
