.AiTagButton {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  width: fit-content;
  width: fit-content;
  min-width: 20px;
  gap: 6px;
  transition: all 0.15s ease-in-out;
  border-radius: 18px;
  white-space: nowrap; /* Prevents text from wrapping to a new line */
  overflow: hidden; /* Hides any text overflow */
  text-overflow: ellipsis;
  padding: 9px 12px 9px 9px;
  text-transform: capitalize;
  word-break: break-word;
  width: fit-content;
}

.AiTagButton-regular {
  background-color: #f3f3f3;
  color: #dc36c4;
  font-family: "Helvetica Now Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.07px;
}
.AiTagButton-regular:hover {
  color: #c52be9;
}

.AiTagButton-selected {
  background-color: #dc36c4;
  color: #ffffff;
  font-family: "Helvetica Now Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.07px;
  .close-icon {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 16px;
    width: 16px;
    color: #dc36c4;
    background-color: #fafafa;
    border-radius: 100%;
    transition: all 0.15s ease-in-out;
  }
}

.AiTagButton-selected:hover {
  background-color: #c52be9;
  color: #ffffff;
  .close-icon {
    color: #c52be9;
  }
}

.AiTagButton-purple-regular {
  background-color: #f3f3f3;
  color: #784dff;
  font-family: "Helvetica Now Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.07px;
}

.AiTagButton-purple-regular:hover {
  color: #c52be9;
}

.AiTagButton-purple-selected {
  background-color: #784dff;
  color: #ffffff;
  font-family: "Helvetica Now Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.07px;
  .close-icon {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 16px;
    width: 16px;
    color: #784dff;
    background-color: #fafafa;
    border-radius: 100%;
    transition: all 0.15s ease-in-out;
  }
}
.AiTagButton-purple-selected:hover {
  background-color: #c52be9;
  color: #ffffff;
  .close-icon {
    color: #c52be9;
  }
}

.AiTagButton-disabled {
  cursor: not-allowed;
}
