.OneOnOnePrepareFeedback {
  height: calc(100% - 75px);
  @media (max-height: 800px) {
    overflow-y: auto;
  }
  .Navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .header-container {
      margin-top: 21px;
    }
    .btn-container {
      display: flex;
      gap: 4px;
    }
  }
  .ai-container {
    margin-top: 40px;
    display: flex;
    height: calc(100% - 90px);
    gap: 12px;
    .ai-sideBar {
      display: flex;
      width: 339px;
      min-height: 500px;
      height: 100%;
      padding: 20px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      border-radius: 28px;
      border: 1px solid #e6e6e7;
      background: #fff;
      .ai-score-card {
        width: 100%;
        min-height: 168px;
        border-bottom: 1px solid #e6e6e7;
        display: flex;
        gap: 3px;
        .icon {
          height: 24px;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
        }
        .info {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 24px;
            .score {
              display: flex;
              .numerator {
                color: #0a0d16;
                font-family: "Helvetica Now Display", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
              }
              .denominator {
                color: #dbdbdc;
                font-family: "Helvetica Now Display", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
              }
            }
          }
          .ai-info {
            margin-top: 16px;
            color: #97989b;
            font-family: "Helvetica Now Display", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 128.571% */
            letter-spacing: -0.07px;
            text-align: left;
            padding-right: 10px;
          }
        }
      }
      .refresh-score {
        display: flex;
        width: 100%;
      }
      .ai-sections {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        gap: 2px;
      }
    }
    .prepare-feedback-container {
      height: 100%;
      overflow-y: auto;
      width: 100%;
      .sidebar-feedback {
        display: flex;
        flex-direction: column;
        flex: 1;
        border-radius: 28px;
        border: 1px solid #e6e6e7;
        background: #fff;
        overflow-y: auto;
        .your-feedback {
          padding: 20px 16px;
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #e6e6e7;
          .text-container {
            margin-top: 20px;
            width: 100%;
            height: fit-content;
            border-radius: 16px;
            border: 1px solid #e6e6e7;
            padding: 16px;
            .custom-text-container {
              height: 240px;
            }
            .image-card-container {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              gap: 6px;
              margin-top: 8px;
            }
          }
        }
      }
    }
    /* .prepare-feedback-container::-webkit-scrollbar {
      display: none;
    } */
  }
}
