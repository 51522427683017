.Actionability {
  padding: 16px;
  display: flex;
  flex-direction: column;
  .learning-section {
    width: 100%;
    display: flex;
    height: fit-content;
    flex-direction: column;
    padding-top: 12px;
    margin-top: 32px;
    .learning-cards-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 32px;
      gap: 11px;
    }
  }
  .btn-container {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
  }
}
