.TextGradientPrimaryButton {
  display: flex;
  padding: 11px 12px;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 18px;
  border: #dbdbdc;
  font-family: "Helvetica Now Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  letter-spacing: -0.07px;
  background: radial-gradient(
    243.07% 243.07% at -3.12% -3.12%,
    #ff6463 0%,
    #ff468b 23.85%,
    #b724ff 49.87%,
    #336bff 80.09%,
    #0090ff 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  border-radius: 18px;
  border: 1px solid #dbdbdc;
  background: #fff;
  transition: all 0.15s ease-in-out;
}

.TextGradientPrimaryButton:hover {
  border-radius: 18px;
  border: 1px solid #dbdbdc;
  background: #fafafa;
}
