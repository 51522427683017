.aiFeedback-container {
  width: 100%;
  height: 422px;
  border-radius: 28px;
  border: 1px solid #e6e6e7;
  background: #fff;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    height: 72px;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #e6e6e7;
    background: #fafafa;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
  }
  .body-container {
    height: calc(100% - 72px);
    display: flex;
    .ai-feedback-sidebar {
      width: 175px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px 0px 24px 24px;
      .ai-score-tag {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .tag-name {
          color: #97989b;
          font-family: "Helvetica Now Display", sans-serif;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 15px;
          letter-spacing: -0.075px;
        }
        .tag-button-score {
          color: #0a0d16;
          font-family: "Helvetica Now Display", sans-serif;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px; /* 100% */
          letter-spacing: -0.4px;
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
    .ai-feedback-info-container {
      flex: 1;
      height: 100%;
      padding: 24px 24px 0px 24px;
      overflow-y: auto;
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none; /* For Internet Explorer 10+ */
      .info-box {
        display: flex;
        height: fit-content;
        border-radius: 12px;
        background: linear-gradient(
          to right,
          #ff6463,
          #ff468b,
          #b724ff,
          #336bff,
          #0090ff
        );
        padding: 1px;
        box-sizing: border-box;
        .info-box-content {
          width: 100%;
          border-radius: 12px;
          padding: 16px;
          min-height: 63px;
          height: fit-content;
          background-color: #fafafa;
          display: flex;
          gap: 30px;
          align-items: center;
          flex-wrap: wrap;
          .ai-feedback-information {
            display: flex;
            flex-direction: column;
            gap: 4px;
            .header-text {
              color: #97989b;
              font-family: "Helvetica Now Display", sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 14px;
              letter-spacing: -0.07px;
            }
            .subtext-container {
              display: flex;
              .ans {
                color: #0a0d16;
                font-family: "Helvetica Now Display", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
              }
              .subtext {
                color: #dbdbdc;
                font-family: "Helvetica Now Display", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
              }
            }
          }
        }
      }
      .ai-feedback-text-container {
        margin-top: 24px;
        color: #76787c;
        font-family: "Helvetica Now Display", sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px; /* 126.667% */
        letter-spacing: -0.075px;
        white-space: pre-wrap;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
