.OneOnOneDetail {
  height: calc(100% - 70px);
  .OneOnOneDetail-navbar {
    height: 35px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
  }
  .OneOnOneDetail-container {
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 40px;
    width: 100%;
    height: calc(100% - 50px - 50px);
    display: flex;
    gap: 12px;

    .notes-container {
      flex: 1;
      padding-left: 36px;
      height: 100%;
      padding-bottom: 10px;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      .engagement {
        border: 1px solid #e6e6e7;
        border-radius: 28px;
        height: 260px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .header-container {
          border-bottom: 1px solid #e6e6e7;
          padding: 18px 16px 18px 24px;
          background-color: #fafafa;
          width: 100%;
          border-top-left-radius: 28px;
          border-top-right-radius: 28px;
          height: 72px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .info {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          padding: 0 24px;
          .status {
            // border-bottom: 1px solid #e6e6e7;
            display: flex;
            align-items: center;
            gap: 40px;
            justify-content: flex-start;
            height: 80px;
            @media screen and (max-width: 1100px) {
              gap: 10px;
            }
            @media screen and (max-width: 1000px) {
              gap: 10px;
            }
            @media screen and (max-width: 1000px) {
              gap: 10px;
            }
          }
          .graph-container {
            flex: 1;
            padding-bottom: 17px;
            .info-graph {
              width: 100%;
              display: flex;
              flex-direction: column;
              .graph {
                display: flex;
                border-radius: 9px;
                gap: 2px;
                background-color: #f3f3f3;
                height: 22px;
                align-items: center;
                padding: 3px;
                margin-top: 27px;
                .completed {
                  height: 16px;
                  border-radius: 6px;
                  background-color: #09b96e;
                }
                .cancelled {
                  height: 16px;
                  border-radius: 6px;
                  background-color: #f8845f;
                }
                .waiting {
                  height: 16px;
                  border-radius: 6px;
                  background-color: #ffc096;
                }
              }
            }
            .status-graph-info {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 8px;
              .info-2 {
                display: flex;
                gap: 20px;
                align-items: center;
              }
            }
          }
        }
      }
    }
    .notes-container::-webkit-scrollbar {
      display: none;
    }
    .aiAssistant {
      min-width: 439px;
      max-width: 400px;
      max-height: 700px;
      border: 1px solid #e6e6e7;
      border-radius: 28px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1050px) {
        min-width: 350px;
        max-width: 350px;
      }
      .header {
        height: 72px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fafafa;
        padding: 18px 16px 18px 20px;
        border-bottom: 1px solid #e6e6e7;
        border-top-right-radius: 28px;
        border-top-left-radius: 28px;
        gap: 12px;
        .assistant-header {
          display: flex;
          align-items: center;
          gap: 12px;
        }
        .view {
          display: flex;
          align-items: center;
          gap: 8px;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          border-radius: 28px;
          border: 1px solid #e6e6e7;
          padding: 8px 12px;
          cursor: pointer;
          .name {
            flex: 1;
            max-width: calc(100% - 12px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .aiAssistant-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 72px);
        .chat {
          padding: 20px;
          padding-bottom: 0;
          display: flex;
          flex-direction: column;
          gap: 4px;
          .chat-container {
            padding-bottom: 10px;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;
            .ai-chat-container {
              height: calc(100% - 34px);
              display: flex;
              flex-direction: column;
              overflow-y: auto;
              -ms-overflow-style: none;
              scrollbar-width: none;
              gap: 12px;
              .message-box {
                color: #76787c;
                font-family: "Helvetica Now Display", sans-serif;
              }
              .message-box h4,
              .message-box h5,
              .message-box h6 {
                color: #76787c;
                font-family: "Helvetica Now Display", sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: -0.075px;
                margin: 0;
              }

              .message-box p,
              .message-box ul,
              .message-box ol,
              .message-box li,
              .message-box blockquote {
                color: #76787c;
                font-family: "Helvetica Now Display", sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: -0.075px;
                margin: 0;
              }
              .message-box h1 {
                color: #76787c;
                font-family: "Helvetica Now Display", sans-serif;
              }

              .message-box h2 {
                color: #76787c;
                font-family: "Helvetica Now Display", sans-serif;
              }

              .message-box h3 {
                color: #76787c;
                font-family: "Helvetica Now Display", sans-serif;
              }
            }
            .ai-chat-container::-webkit-scrollbar {
              display: none;
            }
            .ai-cards-container {
              height: fit-content;
              max-height: 80px;
              width: 100%;
              .ai-cards {
                display: flex;
                flex-wrap: wrap;
                overflow-x: auto;
                gap: 8px;
                height: fit-content;
              }
            }
            .ai-cards-container::-webkit-scrollbar {
              display: none;
            }
          }
        }
        .craft-feedback-container {
          height: 76px;
          display: flex;
          justify-content: center;
          padding: 20px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          border-top: 1px solid #e6e6e7;
        }
      }
    }
  }
}
