.SecondaryButton {
  all: unset;
  box-sizing: border-box;
  display: flex;
  padding: 11px 12px;
  align-items: center;
  gap: 6px;
  background-color: #f3f3f3;
  border-radius: 18px;
  cursor: pointer;
  height: 36px;
}

.SecondaryButton:hover {
  background-color: #ececed;
}
