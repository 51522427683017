@import '~quill/dist/quill.snow.css';

.tml-quill {

  &.is-invalid {

    .ql-snow {
      &.ql-toolbar {
        border-color: $danger;
      }

      &.ql-container {
        border-color: $danger;
      }
    }
  }

  .ql-snow {

    &.ql-toolbar {
      border-color: $input-border-color;
      font-family: unset;
      border-top-left-radius: $input-border-radius;
      border-top-right-radius: $input-border-radius;
    }

    &.ql-container {
      border-color: $input-border-color;
      font-family: unset;
      border-bottom-left-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
    }

    .ql-editor.ql-blank::before {
      font-style: normal;
      font-size: $input-font-size;
      font-family: $input-font-family;
      color: $input-placeholder-color;
    }
  }
}