/**
* = Helper classes
*/

// Overflows
.overflow-visible {
  overflow: visible !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

// Opacity classes
@for $nr from 0 to 10 {
  .opacity-#{$nr} {
    opacity: #{$nr/10} !important;
  }
}

// Fill opacity classes
@for $nr from 0 to 10 {
  .fill-opacity-#{$nr} {
    fill-opacity: #{$nr/10} !important;
  }
}

// Z indexes
@for $nr from 0 to 10 {
  .z-#{$nr} {
    position: relative;
    z-index: #{$nr} !important;
  }
}

.w-auto {
  width: auto !important;
}

// Border widths
.bw-md {
  border-width: $border-width-md !important;
}

.bw-lg {
  border-width: $border-width-lg !important;
}

.bw-xl {
  border-width: $border-width-xl !important;
}

.border-top-left-radius-0 {
  border-top-left-radius: 0 !important;
}

.border-top-right-radius-0 {
  border-top-right-radius: 0 !important;
}

.border-bottom-left-radius-0 {
  border-bottom-left-radius: 0 !important;
}

.border-bottom-right-radius-0 {
  border-bottom-right-radius: 0 !important;
}

.rounded-xl {
  border-radius: $border-radius-xl !important;
}

.teamble-user-image-container {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: $secondary;
  font-size: $font-size-xs;

  img {
    height: inherit;
    width: inherit;
    border-radius: inherit;
  }
}

.flex-basis-50 {
  flex-basis: 50% !important;
}

.flex-basis-33 {
  flex-basis: 33% !important;
}

.mw-33 {
  max-width: 33% !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

p {
  margin-bottom: 0.5rem;
}

.font-family-lato {
  font-family: $font-family-lato !important;
}

.slack-app-badge {
  border-radius: 2px;
  font-size: 10px;
  padding: 1px 3px;
  color: rgba(29, 28, 29, 0.7);
  background-color: rgba(29, 28, 29, 0.13);
  display: inline-block;
  line-height: 1.25;
  font-weight: 700;
}

.teams-hexagon {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  height: 40px;
  width: auto;
}

.ws-no-wrap {
  white-space: nowrap !important;
}

.ws-pre-line {
  white-space: pre-line !important;
}

.ws-pre {
  white-space: pre !important;
}

.border-collapse-separate {
  border-collapse: separate;
}

.border-spacing-0 {
  border-spacing: 0;
}

.feedback-overview-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.download-overview-pdf-btn {
  float: right;
  align-self: flex-end;
  margin: 20px;
  margin-top: -70px;
}

.download-text-span {
  margin-left: 5px;
}

.fill-width {
  width: -webkit-fill-available;
}

.fill-height {
  height: -webkit-fill-available;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
  position: relative;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-10 {
  z-index: 10;
  position: relative;
}

.reviewee-todo-link {
  &.active {
    .reviewee-todo {
      background-color: $gray-400;
    }
  }

  .reviewee-todo {
    &:hover {
      cursor: pointer;
      background-color: $gray-400;
    }
  }
}

.rounded-start-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.translate-middle--x {
  transform: translateX(50%);
}

.width-transition {
  transition: width 0.25s ease-in-out;
}

.mw-0 {
  min-width: 0;
}

.wb-break-word {
  word-break: break-word !important;
}

.flex-basis-0 {
  flex-basis: 0;
}
