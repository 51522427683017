.AiChat {
  position: fixed;
  z-index: 9999;
  bottom: 20px;
  right: 20px;
  width: fit-content;
  height: fit-content;
  background: transparent;

  .expanded {
    border-radius: None;
  }
  .chat_message {
    height: 725px;
    width: 1000px;
    background: white;
    border-radius: 24px;
    padding: 12px 24px;
    border: 1px solid #e2e4e9;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    display: flex;
    gap: 8px;
    .chat_sideBar {
      width: 300px;
      height: 100%;
      background-color: #f8f9fa; // Light gray background
      border-radius: 20px 0 0 20px; // Rounded corners on the left side
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .btn-create {
        width: 100% !important;
        border-radius: 4px;
        font-size: 14px;
      }
    }
    .chat_content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      padding-right: 4px;
      .chat_header {
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .chat_header_title {
          font-size: 18px;
          font-weight: 700;
          color: #333;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          padding: 10px 0;
          transition: color 0.3s ease;

          &:hover {
            color: #007bff;
          }
        }
        .chat_header_close {
          display: flex;
          align-items: center;
          gap: 4px;
          .refresh-icon {
            cursor: pointer;
          }
          .close-icon {
            cursor: pointer;
          }
        }
      }
      .chat_body {
        height: calc(100% - 40px);
        width: 100%;
        // border: 1px solid #e2e4e9;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .chat_body_messages {
          margin-bottom: 10px;
          height: 100%;
          width: 100%;
          overflow-y: auto;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* Internet Explorer 10+ */
          display: flex;
          flex-direction: column;
          gap: 8px;
          .chat-content {
            display: flex;
            flex-direction: column;
            .humanMessage {
              align-self: flex-end;
              background-color: #3d3f44;
              color: white;
              max-width: 70%;
              margin-bottom: 10px;
              padding: 10px;
              border-radius: 10px;
            }
            .aiMessage {
              align-self: flex-start;
              background-color: #f4f7fa;
              color: black;
              max-width: 80%;
              margin-bottom: 10px;
              padding: 10px;
              border-radius: 10px;
            }
          }

          &::-webkit-scrollbar {
            display: none; /* WebKit */
          }
        }
        .chat_body_prompts {
          height: 60px;
          margin-bottom: 8px;
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;

          .prompt-items {
            background-color: rgba(255, 255, 255, 0.8);
            border: 1px solid rgba(209, 217, 230, 0.8); /* Transparent border */
            color: #495057;
            padding: 4px 20px;
            border-radius: 30px;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.3s ease;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            &:hover {
              background-color: rgba(
                255,
                255,
                255,
                0.95
              ); /* Less transparency on hover */
              border-color: rgba(179, 185, 199, 0.8);
              color: #1a202c;
              box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
              transform: translateY(-2px);
            }
            &:focus {
              outline: none;
              box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.25);
            }
            &:disabled {
              background-color: rgba(255, 255, 255, 0.5);
              border-color: rgba(209, 217, 230, 0.5);
              color: rgba(73, 80, 87, 0.5);
              box-shadow: none;
              cursor: not-allowed;
              transform: none;
            }
          }
        }
        .chat_body_input {
          height: fit-content;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          .chat_body_input_text {
            height: 45px;
            width: 95%;
            border-radius: 24px;
            border: none;
            outline: none;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            resize: none;
            overflow: hidden;
            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none; /* Internet Explorer 10+ */
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            &::-webkit-scrollbar {
              display: none; /* WebKit */
            }
            &::placeholder {
              color: #aaa;
              font-size: 14px;
              font-weight: 300;
              opacity: 0.8;
              transform: translateY(-50%);
              position: absolute;
              top: 50%;
              left: 15px;
              transition: all 0.3s ease;
            }

            &:focus::placeholder {
              opacity: 0.7;
            }
          }
          .chat_body_input_button {
            height: 100%;
            width: 100px;
            background: linear-gradient(90deg, #baa7e5 0%, #596ee3 100%);
            cursor: pointer;
            border: none;

            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .onAbort {
            position: relative;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            background: linear-gradient(to right, #c4b3f7, #a48af7, #6b72f5);
            cursor: pointer;
            box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
            .circle {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 25px;
              height: 25px;
              border: 2px solid #8e79e7;
              border-radius: 100%;
              animation: grow-shrink 1s infinite ease-in-out;
            }
            @keyframes grow-shrink {
              0% {
                width: 25px;
                height: 25px;
              }
              50% {
                width: 30px;
                height: 30px;
              }
              100% {
                width: 25px;
                height: 25px;
              }
            }
          }
        }
      }
    }
  }
  .chat-icon {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 2px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 24px;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.06) 0%,
        rgba(255, 255, 255, 0.06) 100%
      ),
      radial-gradient(
        145.84% 145.84% at -3.12% -3.12%,
        #ff6463 0%,
        #ff468b 23.85%,
        #b724ff 49.87%,
        #336bff 80.09%,
        #0090ff 100%
      );
    position: relative;
    cursor: pointer;
    transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
    .outer-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1); /* Smooth transition */
    }
    .inner-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .chat-icon:hover {
    transform: scale(1.1); /* Slightly increase size on hover */
  }
  .chat-icon:hover .outer-icon {
    transform: translate(-50%, -50%) rotate(45deg); /* Rotate the outer icon on hover */
  }
}

.ai-chat-expanded {
  bottom: 10px;
  right: 10px;
}
