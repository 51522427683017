.IconButton {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #f3f3f3;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}
.IconButton:hover {
  background-color: #ececed;
}
