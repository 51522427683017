.AiToolSelector {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 55px;
    background: #fff;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    .text {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: #3d3f44;
        font-family: 'Helvetica Now Display', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 100% */
        letter-spacing: -0.07px;
        .zone {
            width: 6px;
            height: 6px;
            background-color: #ff812d;
            border-radius: 100%;
        }
    }
    .score {
        display: flex;
        align-items: center;
        color: #0a0d16;
        font-family: 'Helvetica Now Display', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 100% */
        letter-spacing: -0.07px;
        .next-icon {
            color: #c2c3c5;
        }
    }
}

.AiToolSelector:hover {
    background-color: #f3f3f3;
    .score {
        .next-icon {
            color: #0a0d16;
        }
    }
}

.AiToolSelector-selected {
    background-color: #f3f3f3;
    .score {
        .next-icon {
            color: #0a0d16;
        }
    }
}
