.CraftFeedBackModal {
  height: 100dvh;
  width: 100vw;
  position: fixed;
  background-color: red;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2); /* Adjust the opacity as needed */
  z-index: 1000; /* Ensure it sits below the modal */
  transition: all 0.16s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  .CraftFeedBack {
    border-radius: 28px;
    width: 514px;
    height: 417px;
    background-color: #ffff;
    .header-container {
      display: flex;
      padding: 22px 28px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .close {
        color: #9e9fa2;
        cursor: pointer;
      }
      .close:hover {
        color: #0a0d16;
      }
    }
    .generating-loader {
      height: calc(100% - 68px);
      background-image: url("../../../../assets/img/feedback-generating-blur.png");
      object-fit: contain;
      border-bottom-left-radius: 28px;
      border-bottom-right-radius: 28px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .loader-text {
        color: #fff;
        font-family: "Helvetica Now Display", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.07px;
        opacity: 0.5;
        margin-top: 8px;
      }
    }
    .craft-feedback {
      padding: 20px 28px 28px 28px;
      background-color: #fafafa;
      flex: 1;
      height: calc(100% - 68px);
      border-bottom-left-radius: 28px;
      border-bottom-right-radius: 28px;
      .feedback-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 241px;
        padding: 16px;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        border-radius: 16px;
        border: 1px solid #e6e6e7;
        background: #fff;
        width: 100%;
        .feedback-text-area {
          width: 100%;
          height: 100%;
        }
        .tab-buttons {
          display: flex;
          gap: 4px;
          max-width: 100%;
          overflow-x: auto;
          -ms-overflow-style: none;
          scrollbar-width: none;
          height: 34px;
        }
        .tab-buttons::-webkit-scrollbar {
          display: none;
        }
      }
      .generate-feedback-container {
        width: 100%;
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
