.TextGradientSecondaryBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    padding: 11px 12px;
    border-radius: 18px;
    cursor: pointer;
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    letter-spacing: -0.07px;
}
.TextGradientSecondaryBtn:hover {
    background-color: #ececed;
}
.craft-feedback-radiant-text {
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    letter-spacing: -0.07px;
    transition: all 0.15s ease-in-out;
    background: radial-gradient(
        243.07% 243.07% at -3.12% -3.12%,
        #ff6463 0%,
        #ff468b 23.85%,
        #b724ff 49.87%,
        #336bff 80.09%,
        #0090ff 100%
    );
    -webkit-background-clip: text; /* Chrome, Safari */
    -moz-background-clip: text; /* Firefox */
    background-clip: text;
    color: transparent;
}
