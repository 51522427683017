.GradientPrimaryButton {
    all: unset;
    display: flex;
    padding: 11px 12px;
    align-items: center;
    gap: 6px;
    justify-content: center;
    background: radial-gradient(
        243.07% 243.07% at -3.12% -3.12%,
        #ff6463 0%,
        #ff468b 23.85%,
        #b724ff 49.87%,
        #336bff 80.09%,
        #0090ff 100%
    );
    color: #fafafa;
    border-radius: 28px;
    cursor: pointer;
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    letter-spacing: -0.07px;
}

.GradientPrimaryButton-disabled {
    background: #ececed;
    color: #c2c3c5;
    cursor: not-allowed;
    padding: 11px 12px;
}
