main {
  background-color: white;
}
.oneOnOneAi {
  display: flex;
  flex-direction: column;
  background: #fff;
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .meetingListContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 170px);
    overflow-y: auto;
    .overview-container {
      width: 100%;
      height: 369px;
      margin-top: 40px;
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      gap: 12px;
      .overview {
        flex: 1;
        height: 100%;
        border-radius: 28px;
        border: 1px solid #e6e6e7;
        background: #fff;
        display: flex;
        flex-direction: column;
        .header {
          display: flex;
          padding: 16px 16px 16px 24px;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e6e6e7;
          background: #fafafa;
          border-top-left-radius: 28px;
          border-top-right-radius: 28px;
        }
        .sub {
          width: 100%;
          height: calc(100% -16px -16px -24px);
          overflow-y: auto;
          border-bottom-left-radius: 28px;
          border-bottom-right-radius: 28px;
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 32px;
          .score {
            height: 63px;
            width: 100%;
            gap: 52px;
            display: flex;
            .aiScore {
              display: flex;
              flex-direction: column;
              .aiScore-header {
                color: #97989b;
                font-family: "Helvetica Now Display", sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 15px; /* 100% */
                letter-spacing: -0.075px;
                margin-bottom: 8px;
              }
              .aiScore-container {
                height: 40px;
                display: flex;
                align-items: center;
                gap: 8px;
                .aiScore-value {
                  color: #0a0d16;
                  font-family: "Helvetica Now Display", sans-serif;
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 40px; /* 100% */
                  letter-spacing: -0.4px;
                }
                .aiScore-increment {
                  color: #f54f1b;
                  font-family: "Helvetica Now Display", sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 12px; /* 100% */
                  height: 100%;
                }
              }
            }
          }
          .graph-container {
            height: 154px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .graph-label {
              display: flex;
              padding: 16px;
              justify-content: space-between;
              align-items: center;
              align-self: stretch;
              height: 66px;
              border-radius: 12px;
              background: #f3f3f3;
              .labels {
                display: flex;
                .label-tag {
                  width: 120px;
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  .header-text {
                    color: #97989b;
                    font-family: "Helvetica Now Display", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px; /* 100% */
                    letter-spacing: -0.07px;
                  }
                  @media screen and (max-width: 1000px) {
                    width: 100px;
                  }
                }
              }
            }
            .bar-graph {
              height: 64px;
              .container-graph {
                border-radius: 9px;
                background: #f3f3f3;
                padding: 3px;
                display: flex;
                gap: 2px;
                align-items: center;
                .bar {
                  border-radius: 6px;
                  height: 16px;
                  position: relative;
                  .bar-label {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    position: absolute;
                    left: 0;
                    top: calc(100% + 10px);
                    .label {
                      color: #97989b;
                      font-family: "Helvetica Now Display", sans-serif;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 14px; /* 100% */
                      letter-spacing: -0.07px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .summary {
        width: 339px;
        height: 100%;
        overflow-y: auto;
        border-radius: 28px;
        border: 1px solid #e6e6e7;
        display: flex;
        flex-direction: column;
        .header {
          display: flex;
          width: 100%;
          padding: 18px 16px 18px 20px;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e6e6e7;
          background: #fafafa;
        }
        .paragraph {
          width: 100%;
          height: calc(100% - 18px -18px - 24px);
          overflow-y: auto;
          border-bottom-left-radius: 28px;
          border-bottom-right-radius: 28px;
          padding: 20px;
          color: #76787c;
          font-family: "Helvetica Now Display", sans-serif;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px; /* 126.667% */
          letter-spacing: -0.075px;
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none;
        }
        .paragraph::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .meetingListTabsContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 40px;
      border-bottom: 1px solid #e6e6e7;
      margin-top: 20px;
      .tabs {
        display: flex;
        gap: 24px;
      }
      .filters {
        display: flex;
        align-items: center;
        gap: 16px;
      }
      .global-search {
        border: none;
        outline: none;
        color: #3d3f44;
        font-family: "Helvetica Now Display", sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: -0.075px;
        border-radius: 6px;
        border: 1px solid #e6e6e7;
        background: #fff;
        padding-left: 10px;
        height: 32px;
        width: 240px;
        &::placeholder {
          color: #9e9fa2;
          font-family: "Helvetica Now Display", sans-serif;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: -0.075px;
        }
      }
    }
    .tableContainer {
      overflow-y: auto;
      min-height: 200px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
