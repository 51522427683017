/*
 * Sidebar
*/
.sidebar {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08),
    0px 4px 32px 0px rgba(0, 0, 0, 0.08);
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  background: white;
  @include transition(max-width 0.3s);

  @include media-breakpoint-up(md) {
    width: 100%;
    max-width: 260px;
  }

  .nav {
    white-space: nowrap;
  }

  .nav-item {
    border: $border-width solid transparent;

    & > .nav-link {
      &.active {
        color: $light;
        background-color: #f4f7fa;
      }
    }

    .nav-link {
      color: $light;
      border: $border-width solid transparent;

      &:hover {
        color: $light;
        background-color: #f4f7fa;
      }

      &.active {
        color: $white;
      }
    }

    margin-bottom: 0.2rem;
  }

  .nav-link + .multi-level {
    margin-top: 0.2rem;
  }

  .nav-link {
    font-size: $font-size-base;
    vertical-align: middle;
    padding: 0.55rem 0.75rem;
    @include border-radius($border-radius);

    .sidebar-icon {
      margin-right: 0.5rem;
      color: #3d3f44;

      span {
        min-width: 22px;
      }

      svg {
        min-width: 22px;
      }

      &.svg-icon {
        margin-right: 14px;
      }
    }

    .link-arrow {
      font-size: $font-size-sm;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.1rem rgba(#4c5680, 0.2);
    }
  }

  .nav-link.active {
    color: $primary;
  }

  .multi-level {
    .nav-link {
      padding-left: 45px;
    }
  }

  .sidebar-text {
    color: #3d3f44;
  }

  .sidebar-text,
  .link-arrow,
  .badge,
  .notification-count {
    opacity: 1;
    @include transition(opacity 0.3s);
  }

  @include media-breakpoint-up(md) {
    .sidebar-text-contracted {
      display: none;
      @include transition(opacity 0.3s);
    }

    &.contracted {
      .sidebar-text,
      .link-arrow,
      .badge {
        opacity: 0;
      }

      .notification-count {
        opacity: 1;
        position: absolute;
        top: 0;
        left: 35px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-size: 0.7rem;
        padding: 0.2rem;
      }

      .nav-item {
        position: relative;
        width: 47px;

        & > .nav-link {
          &.active {
            background-color: #f4f7fa;
          }
        }

        .nav-link {
          &:hover {
            background-color: #f4f7fa;
          }
        }
      }

      .sidebar-text-contracted {
        display: inline;
      }

      .multi-level .nav-link {
        padding-left: 17px;
      }

      .nav-item {
        white-space: nowrap;
      }

      .sidebar-icon {
        text-align: center;
      }

      // @include media-breakpoint-up(md) {
      // 	&+.content {
      // 		margin-left: 95px;

      // 	}
      // }

      max-width: 95px;
    }
  }
}

.sidebar-inner {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sidebar-items {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .sidebar-admin-view-card {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    animation-delay: 0.1s;
    .user-view {
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-bottom: 1px solid #e6e6e7;
      height: 56px;
      .name {
        color: #3d3f44;
        font-family: "Helvetica Now Display", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 100% */
        letter-spacing: -0.07px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .toggle-view {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 16px;
      .view {
        color: #3d3f44;
        font-family: "Helvetica Now Display", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.07px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .subtext {
        color: #c2c3c5;
        font-family: "Helvetica Now Display", sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;
        letter-spacing: -0.13px;
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .accordion-button {
    &::after {
      background-image: none;
      content: "\f054";
      font-family: $font-awesome-5;
      font-size: $font-size-sm;
      font-weight: 900;
      height: 1.25rem;
      width: 0.5rem;
    }

    &:not(.collapsed) {
      background-color: #f4f7fa;

      &::after {
        transform: rotate(90deg);
        transition: all 0.2s ease;
      }
    }
  }

  .accordion {
    padding-top: 0.25rem;
  }

  .accordion-body {
    padding: 0.5rem 0 0 0;
  }

  .accordion-collapse {
    border: 0;
  }
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.user-card {
  border-bottom: 0.0625rem solid $teamble-color-nav;
}

@include media-breakpoint-down(sm) {
  .sidebar {
    width: 100%;
  }
}

.content {
  overflow: visible;
  padding: 0 $spacer 0 $spacer;
  @include transition(all 0.3s);

  @include media-breakpoint-up(md) {
    margin-left: 260px;
  }
}

.sidebar-toggle {
  font-size: 1.3rem;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    background: $gray-400;
  }
}

.sidebar-transition-enter {
  opacity: 0;
}

.sidebar-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.sidebar-transition-exit {
  opacity: 1;
}

.sidebar-transition-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

@media (min-width: 768px) {
  .sidebar.contracted + .content {
    margin-left: 95px;
  }
}
