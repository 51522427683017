.TableListManagerView {
  height: fit-content;
  width: 100%;

  table-layout: fixed;
  border-collapse: collapse;
  .table-row {
    height: 50px;
    padding: 12px 0px;
    cursor: pointer;
  }
  .table-row-empty {
    height: 50px;
    padding: 12px 0px;
    .empty-row-cell {
      color: #6c6e73;
      font-family: "Helvetica Now Display", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.07px;
      gap: 4px;
    }
  }
  thead {
    position: sticky;
    top: 0;
    background-color: #ffff;
  }
  th {
    width: 100%;
    .header-cell {
      color: #6c6e73;
      font-family: "Helvetica Now Display", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.07px;
      // width: 100%;
      display: flex;
      align-items: flex-end;
      gap: 4px;
    }
  }
  th,
  td {
    border-bottom: 1px solid #e6e6e7;
  }
  .next-arrow {
    .arrow-cell {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  td {
    color: #0a0d16;
    font-family: "Helvetica Now Display", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    letter-spacing: -0.07px;
    padding-right: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .checkBox {
      width: 14px;
      height: 14px;
      border: 1px solid #c2c3c5;
    }
  }
  .checkBox {
    width: 30px;
  }

  .next-arrow {
    width: 40px;
  }
  .table-row {
    transition: all 0.15s ease-in-out;
  }
  .table-row:hover {
    background: #f3f3f3;
  }
}
