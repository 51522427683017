.OneOnOneObjectivesCard {
  border: 1px solid #e6e6e7;
  margin-top: 12px;
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  border-radius: 28px;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);

  .header-container {
    padding: 24px;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    border-bottom: 1px solid #e6e6e7;
    background: #fafafa;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .subtext {
      color: #c2c3c5;
      font-family: "Helvetica Now Display", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.16px;
    }
  }

  .container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    .no-notes {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Helvetica Now Display", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 100% */
      letter-spacing: -0.16px;
    }
    .questions {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 4px;
      padding: 16px;
      border: 1px solid #e6e6e7;
      border-radius: 12px;

      .information {
        display: flex;
        flex-direction: column;

        .subtext {
          color: #7a7a7c;
          font-family: "Helvetica Now Display", sans-serif;
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.075px;
          margin-top: 6px;
          word-wrap: break-word;
          word-break: break-word;
          overflow-wrap: break-word;
          white-space: normal;
        }

        .para {
          color: #0a0d16;
          font-family: "Helvetica Now Display", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.16px;
          margin-top: 12px;
        }
      }

      .kpis {
        margin-top: 12px;
        display: flex;
        flex-direction: column;

        .kpis-container {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .kpi-name {
            font-family: "Helvetica Now Display", sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.075px;
            margin-top: 4px;
            white-space: pre-wrap;
            color: #505050;
          }
        }
      }
    }
  }
}
