.PrimaryButton {
  all: unset;
  box-sizing: border-box;
  display: flex;
  padding: 11px 12px;
  align-items: center;
  gap: 6px;
  justify-content: center;
  border-radius: 18px;
  background: #0a0d16;
  color: #fafafa;
  font-family: "Helvetica Now Display", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  letter-spacing: -0.07px;
  height: 36px;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.PrimaryButton:hover {
  background-color: #171717;
  color: #fafafa;
}
