.teamble-divider {
  width: 100%;
  height: 2px;
  background-color: $gray-500;
  margin: ($spacer/2) 0;

  &.vertical {
    width: 2px;
    height: 100%;
    margin: 0 ($spacer/2) ;
  }
}