.ImageCard {
  width: 323px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid#e6e6e7;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08),
    0px 4px 32px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;

  .img-card {
    height: 140px;
    width: 100%;
    border-radius: 16px 16px 0px 0px;
  }
  .image-card-info {
    display: flex;
    height: 132px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    justify-content: space-between;
    .subtext {
      color: #c2c3c5;
      font-family: "Helvetica Now Display", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 100% */
      letter-spacing: -0.07px;
      margin-top: 6px;
    }
    .add-video {
      color: #dc36c4;
      font-family: "Helvetica Now Display", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px; /* 100% */
      letter-spacing: -0.07px;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
