.AiTone {
  padding: 16px;
  display: flex;
  flex-direction: column;
  .btn-container {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
  }
}
