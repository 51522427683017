.CustomTextArea {
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  outline: none;
  color: #76787c;
  font-family: "Helvetica Now Display", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 126.667% */
  letter-spacing: -0.075px;
}
.CustomTextArea::placeholder {
  color: var(--Neutral-063, #9e9fa2);
  font-family: "Helvetica Now Display", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.075px;
}
.CustomTextArea::selection {
  background: rgba(220, 54, 196, 0.2);
  color: #3d3f44;
}
